import { useState } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";

import { INITIAL_STEP, ERROR_STEP } from "constants/steps";
import { getAllStatistics } from "api/statistics";
import DownloadCsvControls from "components/DownloadCsvControls";
import BasicStep from "../BasicStep";
import csvFileName from "./csvFileName";

function SuccessStepAll({ setStep }) {
  const [loading, setLoading] = useState(false);

  async function handleDownload() {
    setLoading(true);

    try {
      const statisticsBatch = await getAllStatistics([
        localStorage.getItem("rangeStartAt"),
        localStorage.getItem("rangeEndAt"),
      ]);
      saveAs(
        statisticsBatch,
        csvFileName(
          "all_partners",
          "ALL",
          localStorage.getItem("rangeStartAt"),
          localStorage.getItem("rangeEndAt")
        )
      );
      setLoading(false);
    } catch {
      setLoading(false);
      setStep(ERROR_STEP);
    }
  }

  function renderControls() {
    return (
      <>
        {loading && <BasicStep.Loader />}
        <DownloadCsvControls
          onDownloadButtonClick={handleDownload}
          onCloseButtonClick={() => setStep(INITIAL_STEP)}
        />
      </>
    );
  }

  return (
    <BasicStep headline="Success!" controls={renderControls()}>
      <BasicStep.Text>
        Activation codes usage statistics for <strong>all partners</strong>{" "}
        gathered successfully.
      </BasicStep.Text>
    </BasicStep>
  );
}

SuccessStepAll.propTypes = {
  setStep: PropTypes.func.isRequired,
  range: PropTypes.array,
};

export default SuccessStepAll;
