import { useQuery, useQueryClient } from "react-query";

import fetchData from "./fetchData";
import {
  denormalizedPartner,
  denormalizedPartnerForUpdate,
  normalizedPartner,
} from "./normalization";

const PARTNERS_QUERY_KEY = "partners";

export async function getPartners() {
  const partners = await fetchData("/partners?size=1000", { method: "GET" });
  const normalizedPartners = partners.map((partner) =>
    normalizedPartner(partner)
  );
  return normalizedPartners;
}

export async function createPartner(newPartner) {
  const partnerDetails = await fetchData("/partners", {
    method: "POST",
    body: {
      ...denormalizedPartner(newPartner),
    },
  });

  return normalizedPartner(partnerDetails);
}

export async function updatePartner(updatedPartner) {
  const partnerDetails = await fetchData("/partners", {
    method: "PUT",
    body: {
      ...denormalizedPartnerForUpdate(updatedPartner),
    },
  });

  return normalizedPartner(partnerDetails);
}

export function useGetPartners() {
  const { data: partners, status } = useQuery(
    PARTNERS_QUERY_KEY,
    getPartners,
    {}
  );
  return { partners, status };
}

export function useInvalidatePartners() {
  const queryClient = useQueryClient();

  function invalidatePartners() {
    queryClient.invalidateQueries(PARTNERS_QUERY_KEY);
  }

  return invalidatePartners;
}
